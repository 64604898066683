<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col class="mt-4">
        <b-card header="POLITICA DE PRIVACIDADE">
          <b-card-text v-html="politica"></b-card-text>
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      politica: "Arquivo de politica da empresa não definido",
    };
  },
  methods: {
    async get_politica(unityId) {
      const response = await this.$store.dispatch("http_get", {
        url: `/politica/${unityId}`,
      });
      if (response.data) {
        try {
          this.politica = response.data.payload[0].description;
        } catch (error) {
          console.log("ERRO ====>", error);
        }
      }
    },
  },
  mounted() {
    const unityId = this.$route.params.unityId;
    this.get_politica(unityId);
  },
};
</script>

<style></style>
